@import url('https://fonts.googleapis.com/css2?family=Lilita+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Freeman&display=swap');

.App {
  text-align: center;
}

.lobby-container {
  display: flex;
  justify-content: center; /* Horizontal center */
  align-items: center; /* Vertical center */
  height: 100vh; /* Full viewport height */
  border: 1px solid #000; /* For visualization */
}

input {
  width: 100%;
  box-sizing: border-box;
  padding: 5px;
  background: rgba(0, 0, 0, 0.5);
  border: none;
}

.chat input {
  width: calc(100% - 12px);
  margin-left: 6px;
  margin-right: 6px;
  box-sizing: border-box;
  float: none;
  height: 25px;
  border-radius: 2px !important;

}

.leaf-dark-border {
  border-bottom: 3px solid rgb(87, 175, 71) !important;
}

.leaf {
  color: rgb(171, 255, 157);
}

.water {
  color: rgb(157, 227, 255);
}

.water-dark-border {
  border-bottom: 3px solid rgb(63, 150, 185) !important;
}

.chat .banner-content {
  padding-left: 0px;
  padding-right: 0px;
}



.colour-container {
  width: 100%;
  align-content: center;
}

.lobby {
  /* width: 26vw; */
  height: 70vh;
  width: 370px;
  display: inline-block;
  /* flex-direction: column;
  justify-content: center;
  align-items: center; */
  /* height: 100vh; */
  background-color: var(--secondary-color);
  border-radius: 6px;
  padding: 20px;
  /* height: 100vh; */
  /* margin: 0 auto; */
}

.words {
  width: 340px;
  order: 2;
}

.round-details {

}

.scoreboard, .chat {
  width: 205px;
}

.scoreboard {
  order: 1;
}

.chat {
  order: 3;
}

.player:hover {
  cursor: pointer;
  opacity: 0.8;
}

.modal {
  background: var(--secondary-color);
}

.lobby.teams {
  height: calc(52vh - 60px);
}

.lobby-container.settings {
  flex-direction: column;
}

.lobby.buttons {
  height: 18vh;
  min-height: 170px;
  margin-top: 20px;
}

@media (max-width: 830px) {
  .words {
    width: 40vw;
  }

  /* .lobby {
    width: 60vw;
  } */

  .scoreboard, .chat {
    width: 20vw;
  }
}

@media (max-width: 665px) {
  .words {
    width: 40vw;
  }

  .lobby {
    width: 75vw;
  }

  .scoreboard, .chat {
    width: 23vw;
  }
}

@media (max-width: 600px) {
  .lobby {
    width: 85vw;
  }
  .words {
    width: 85vw;
    order: 1;
  }

  .scoreboard, .chat {
    width: 85vw;
  }

  .scoreboard {
    order: 2;
  }

  .chat {
    order: 3;
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.color-picker {
  display: inline-block;
  width: 45px;
  height: 45px;
  border-radius: 100%;
  margin: auto;
  border: 2px solid var(--tertiary-color);
  margin: 0 2px;
}

.color-picker:hover {
  cursor: pointer;
  opacity: 0.8;
}

.App-header {
  /* background-color: #282c34; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  /* color: #61dafb; */
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.game {
  /* background: rgba(255, 255, 0, 0.5); */
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center top;
  flex-wrap: wrap;
  margin: 0 auto;
  width: fit-content;
}

.speaker-buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: auto;
}

.speaker-buttons button {
  flex: 1; /* This makes each button grow to fill the space */
  margin: auto;
  margin-top: 8px;
  border-radius: 4px; 
  /* background: #000; */
  border: none;
  height: 3em;
  margin-right: 5px; /* Adjust the value as needed, last button should not have margin-right */
}

/* To remove margin-right from the last button */
.speaker-buttons button:last-child {
  margin-right: 0;
}

button {
  /* color: #111; */
  font-size: 0.8em;
  padding: 0;
  /* font-family: "Freeman", sans-serif; */
  font-weight: bold;
  font-family: "Freeman";
}

button:disabled:hover {
  cursor: not-allowed;
}

button:not(:disabled):hover {
  cursor: pointer;
  opacity: 90% !important;
}

button.fail {
  color: #7a131c;
  background: #ec596a;
  border-bottom: 3px solid #d14152;
}

input {
  color: #FFF !important;
}

button.success {
  color: #337a13;
  background: #79e049;
  border-bottom: 3px solid #61c632;
}

.words {
  display: flex;
  flex-direction: column;
}

@media (max-width: 600px) {
  .game {
    flex-direction: column;
    align-items: center;
  }
  /* .scoreboard, .chat {
    width: 100%;
  }
  .words {
    width: 100%;
  } */
}

/* div.game, .game div {
  border: 1px solid #FFF;
} */

.scoreboard, .words, .chat {
  margin: 5px;
}

.scoreboard {
  /* background: rgba(255, 0, 0, 0.5); */
  /* width: 16vw; */
  float: right;
  align-items: right;
  border-radius: 0px 0px 0px 4px;
  /* background: #FFF;
  background-clip: padding-box; */
}

.words {
  /* background: rgba(0, 255, 0, 0.5); */
  /* width: 22vw; */
}

.chat {
  /* background: rgba(0, 0, 255, 0.5); */
  /* width: 16vw; */
  border-radius: 0px 0px 4px 0px;
  /* background: #FFF; */
}

.banner {
  /* background: rgba(41, 41, 186, 1); */
  /* background: rgba(68, 81, 223, 0.8); */
  /* background: rgba(255, 255, 255, 0.2); */
  background: var(--tertiary-color);

  /* background: rgb(9,9,121); */
/* background: rgba(41, 41, 186, 1) linear-gradient(0deg, rgba(9,9,121,0.3) 0%, rgba(41,41,186,0.3) 52%, rgba(0,212,255,0.3) 100%); */
  border-radius: 4px 4px 0 0;
  /* border: 2px solid rgba(255, 255, 255, 0.1); */
  /* border-bottom: none !important; */
  font-size: 1em;
  padding: 8px;
}

.game {
  /* border: 1px solid #ff0; */
  /* border-radius: 100px; */
  margin: 0px 15px;
  /* padding: 5px 0px; */
  /* background: var(--background-color-half-tp); */
  width: auto;
}

.scoreboard .banner-content {
  height: calc(176px - 44px);
}

#pack-select {
  width: 100%;
  height: 25px;
  background: rgba(0, 0, 0, 0.5);
  border: none;
}

.label {
  margin-left: 3px !important;
  float: left;
  text-align: left;
  margin: 10px 0px 0px 0px;
  font-size: 0.75em;
  color: rgba(255, 255, 255, 0.9);
}

.chat .banner-content {
  /* height: 150px;z */
}

.chat-box {
  height: 150px; 
  overflow-y: auto; /* Enables vertical scrolling */
  display: flex;
  flex-direction: column-reverse;
  overscroll-behavior-y: contain;
  scroll-snap-type: y proximity;
}

.chat-box::-webkit-scrollbar {
  display: none;
}

input {
  border: 1px solid #000;
  border-radius: 4px;
  /* padding: 4px; */
  /* margin: 4px; */
}

.chat-box::-webkit-scrollbar-track {
  background: #ff0000; 
}

.system-message-red {
  color: #7a131c;
  background-color: #ec596a;
  /* border-top: 1px solid rgb(122, 19, 28, 0) !important; */
}

.system-message-green {
  color: #337a13;
  background-color: #79e049;
  /* border-top: 1px solid rgb(51, 122, 19, 0.3) !important; */
}

.system-message-blue {
  color: #16124c;
  background-color: #6b6bd9;
  /* border-top: 1px solid #16124c !important; */
}

.banner-content {
  padding: 6px 0px;
  /* background: rgba(255, 255, 255, 0.1); */
  background: var(--secondary-color);
  border-radius: 0px 0px 4px 4px;
  color: #fff;

  /* border: 1px solid var(--tertiary-color); */
  border-top: none !important;

  /* border: 1px solid #eee; */
  /* border-top: none !important; */
}

.words .banner-content {
  /* border: 2px solid var(--tertiary-color); */
}

.round-details > div {
  display: flex;
  border-radius: 4px;
  /* background: rgba(0, 0, 0, 0.1); */
  color: var(--text-on-bg-color);
  height: 20vh;
}

/* .chat-box {
  border-left: 1px solid var(--secondary-color);
  border-right: 1px solid var(--)
} */

.words .banner-content {
  min-height: 215.96px;
  box-sizing: border-box;
}

.round-details {
  margin-top: 20px;
  display: flex;
    justify-content: center;
    align-items: center;

  }

  .round-details div {
    /* margin: 20px; */
    vertical-align: center;
    margin: auto 20px;
  }

 div {
  /* border: 1px solid #FF0000; */
  /* outline: 1px solid #FF0000; */
}

.banner-content {
  /* box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1); */
}

.banner, .banner-content {
  padding-left: 10px;
  padding-right: 10px;
}

.controls-container {
  margin-top: auto !important;

}

select {
  border-radius: 2px;
}

.chat-message {
  font-size: 0.8em;
}

.chat input {
  margin-top: 7px;
}

.chat .banner-content {
  padding-top: 0px; 
}

.controls-box {
  display: flex;
  /* flex-wrap: wrap  ; */
  width: 100%;
  padding-bottom: 15px;
}

.control {
  flex: 1 100%;
  padding-right: 4px;
  box-sizing: border-box;
  margin-bottom: 10px;
  /* padding: 5px; */
  /* width: 100%; */
}

.control:last-of-type {
  padding-right: 0;
  padding-left: 4px;
}

.control select {
  width: 100%;
  height: 25px;
  background: rgba(0, 0, 0, 0.5);
  border: none;
}

.teams-container {
  display: flex;
  flex-wrap: wrap;
  /* width: auto; adjust based on your needs */
}

.teams-box * {
   margin: 0;
}

.teams-box {
  flex: 1 1 50%; /* Each box takes up 50% of the container's width */
  box-sizing: border-box;
  /* border: 1px solid #000; border to visualize the boxes */
}

#change-team {
  /* margin-bottom: 10px; */

}

#join-game, #start-game, #create-game, #change-team {
  border-radius: 2px !important;
  align-self: center;
  margin-top: auto !important;
  color: var(--secondary-color);
  background: #FFF;
  border: none;
  border-radius: 4px;
  font-family: "Freeman", sans-serif;
  font-size: 1.3em;
  height: 35px;
  width: 65%;
}

.scoreboard .banner-content {
  border-radius: 0px !important;
}

.sixteen-width {
  width: 16px;
}

.icon-pad-right {
  margin-right: 5px;
}

#waiting-for {
  align-self: center !important;
  margin-top: auto;
  float: none;
  text-align: center;
  opacity: 0.5;
}

#start-game:disabled {
  opacity: 0.5;
}

#join-game:disabled {
  opacity: 0.5;
}

#create-game:disabled {
  opacity: 0.5;
}

*:disabled:hover {
  cursor: not-allowed;
}

#start-game:disabled:hover {
  opacity: 0.5;
  /* cursor: not-allowed;  */
}

#join-game:disabled:hover {
  opacity: 0.5;
  /* cursor: not-allowed;  */
}

#create-game:disabled:hover {
  opacity: 0.5;
  /* cursor: not-allowed;  */
}

form {
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
}

#join-game:hover, #start-game:hover {
  opacity: 0.8;
}

.banner, .banned-word, h1 {
  font-family: "Freeman", sans-serif;
  text-transform: uppercase;
}

.words {
  /* font-weight: bold; */
  font-size: 1.5em;
}

.words .banned-word {
  color: #fff;
  font-size: 1em;
  padding: 0.1em 0em;
}

.chat-message {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding: 4px 8px 4px 8px;
  text-align: left;
}